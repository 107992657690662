<template>
    <div style="justify-content:center">
        <b-card align="center">
            <b-card-body class="text-center" title="Gracias por tu Inversión, por favor completa la siguiente Información.">
                                
                <b-row style="justify-content:center">
                    <b-col md="3" xl="3" class="mb-2">
                        <b-form-group label="1. Selecciona Género" label-for="basicInput">                        
                            <b-form-select v-model="userProfileDetails.gender" :options="genderTypeOptions" placeholder="Seleccione"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row style="justify-content:center">
                    <b-col md="3" xl="3" class="mb-2">
                        <label for="example-datepicker">2. Fecha de Nacimiento</label>
                        <b-form-datepicker v-model="userProfileDetails.birthDate" id="example-datepicker1" class="mb-1"/>
                    </b-col>
                </b-row>

                <b-row style="justify-content:center">
                    <b-col md="3" xl="3" class="mb-1">
                        <b-form-group label="3. Qué te impulso a invertir en Sosty? (selección múltiple, presionando tecla ctrl o cmd)" label-for="basicInput">                        
                            <b-form-select multiple v-on:change="CheckOtroOption" v-model="motivationToInvest" :options="opinionsToSelect" :select-size="5" placeholder="Seleccione"/>
                        </b-form-group>
                    </b-col>                    
                </b-row>

                <b-row style="justify-content:center">
                    <b-col v-if="this.enableField" md="3" xl="3" class="mb-3">                        
                        <label for="example-datepicker">Cuál?</label>
                        <b-form-input v-model="motivationToInvestDetails" placeholder="Escribe tu respuesta" rows="5"/>                        
                    </b-col>                    
                </b-row>

                <b-row style="justify-content:center">
                    <b-col md="3" xl="3" class="mb-2">
                        <b-form-group label="4. Con cúal de las siguientes frases te identificas más en Sosty? (respuesta única)" label-for="basicInput">                        
                            <b-form-select v-model="phraseIdentifiesMe" :options="phrasesToSelect" placeholder="Seleccione"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row style="justify-content:center">

                    <b-col v-if="phraseIdentifiesMe == 'Otro' " md="3" xl="3" class="mb-3">                        
                        <label for="example-datepicker">Cuál?</label>
                        <b-form-input v-model="phraseIdentifiesMeDetails" placeholder="Escribe tu respuesta" rows="5"/>                        
                    </b-col>                                        
                </b-row>

                <b-row style="justify-content:center">
                    <b-col md="3" xl="3" class="mb-2">
                        <b-form-group label="5. ¿Que tanto se de ganadería? (respuesta única)" label-for="basicInput">                        
                            <b-form-select v-model="knowledgeAboutCattleRaising" :options="experienceToSelect" placeholder="Seleccione"/>
                        </b-form-group>
                    </b-col>                       
                </b-row>         
            
                <b-row style="justify-content:center">
                    <b-col v-if="!processing" class="mt-2" md="3" lg="3"  >
                        <b-button variant="primary" @click="SaveProfileDetails" block>Guardar</b-button>
                    </b-col>
                    <b-col v-if="processing" class="mt-2" md="3" lg="3">
                        <b-button variant="primary" disabled block><b-spinner small label="Cargando..." /></b-button>
                    </b-col>
                </b-row> 
                        
            </b-card-body>  
        </b-card>   
    </div>
</template>

<script>
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import BCardCode from '@core/components/b-card-code'
import {
  BModal, BTabs, BTab, BFormInput, BCalendar,  BRow, BCol, BcardText, BFormGroup, BCardBody, BFormDatepicker, BFormTextarea, BFormFile, BButton, BFormSelect, BSpinner,BBadge, BCardText, BFormCheckbox,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import {
  LMap, LTileLayer, LMarker, LPopup,
} from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import departamentos from '../../assets/departamentos-municipios.json'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BCardBody,
    BCalendar,    
    BTabs,
    BcardText, 
    BTab,
    BFormInput,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    Cleave,
    BFormDatepicker,
    BFormTextarea,
    LMap, 
    LTileLayer, 
    LMarker, 
    LPopup,
    BFormFile,
    BButton,
    BFormSelect,
    BSpinner,
    BBadge,
    BCardText,
    BFormCheckbox,
  },
data() {
    return {
        motivationToInvest: [],
        phraseIdentifiesMe: null,
        phraseIdentifiesMeDetails: null,
        knowledgeAboutCattleRaising: null,
        enableField: false,
        payload:{

        },    
        userProfileDetails: {},
        
        genderTypeOptions: [
            { value: 'Femenino', text: 'Femenino' },
            { value: 'Masculino', text: 'Masculino' },
        ], 

        opinionsToSelect: [
            
            {value: 'Rentabilidad atractiva.', text: 'Rentabilidad atractiva.'},
            {value: 'Proyectos ganadería sostenible y bienestar animal.', text: 'Proyectos ganadería sostenible y bienestar animal.'},
            {value: 'No tengo que hacer nada.', text: 'No tengo que hacer nada.'},
            {value: 'Seguridad y transparencia.', text: 'Seguridad y transparencia.'},
            {value: 'Me permite seguimiento.', text: 'Me permite seguimiento.'},
            {value: 'Me parece fácil de usar.', text: 'Me parece fácil de usar.'},
            {value: 'El modelo de negocio me gusta.', text: 'El modelo de negocio me gusta.'},
            {value: 'Otro:', text: 'Otro:'}
            
        ],
        
        phrasesToSelect: [
            {value: 'Lo más importante para mi es la rentabilidad.', text: 'Lo más importante para mi es la rentabilidad.'},
            {value: 'Quiero promover/invertir en modelos ambientalmente sostenibles.', text: 'Quiero promover/invertir en modelos ambientalmente sostenibles.'},
            {value: 'Quiero aprender sobre ganadería.', text: 'Quiero aprender sobre ganadería.'},
            {value: 'He tenido ganado, pero ya no lo puedo o quiero administrar.', text: 'He tenido ganado, pero ya no lo puedo o quiero administrar.'},
            {value: 'Me gustaría ser ganadero.', text: 'Me gustaría ser ganadero.'},
            {value: 'Otro', text:'Otro'}

        ],
        
        experienceToSelect: [
            {value: 'No se nada, es el primer contacto que tengo.', text: 'No se nada, es el primer contacto que tengo.'},
            {value: 'Conocimiento básico.', text: 'Conocimiento básico.'},
            {value: 'Conocimiento medio.', text: 'Conocimiento medio.'},
            {value: 'Conocimiento alto.', text: 'Conocimiento alto.'}               
        ],
        
        processing: false, 
        motivationToInvestToString: false,   
        checkOtroWord: false,  
        motivationToInvestDetails: null,   
    }
    },
created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.userID != null) {
      this.userID = this.$route.query.userID;
    }   
    this.GetUserById()    
},
watch:{
    motivationToInvestToString: function(val) { this.motivationToInvestToString = val; },
},
methods: {
    GetUserById(){
    axios.get('/User/GetUserbyID', { params: { userID: this.userID}})
        .then(response => {
          this.user = response.data;        
          console.log(this.user.userID)
          console.log(this.user.userType)
          this.GetUserProfileDetails() 
        })
    },

    GetUserProfileDetails(){
        axios.get('/User/GetProfileDetails', { params: { userID: this.userID, userType: this.user.userType  }})
        .then(response => {
            this.userProfileDetails = response.data

            if(this.userProfileDetails.gender != null
                && this.userProfileDetails.birthDate != null
                && this.userProfileDetails.phraseIdentifiesMe != null
                && this.userProfileDetails.motivationToInvest != null
                && this.userProfileDetails.knowledgeAboutCattleRaising != null
                ){
                    this.$router.replace('/my-investments');
            }
        })
    },
    SaveProfileDetails(){
        this.processing = true;
        
        if(this.checkOtroWord){
            this.userProfileDetails.motivationToInvest = this.motivationToInvest.toString()
            this.userProfileDetails.motivationToInvest = this.userProfileDetails.motivationToInvest + this.motivationToInvestDetails

        } else {
            this.userProfileDetails.motivationToInvest = this.motivationToInvest.toString() 
        }
        
        if(this.phraseIdentifiesMe == 'Otro'){
            this.userProfileDetails.phraseIdentifiesMe = this.phraseIdentifiesMeDetails
        } else {
            this.userProfileDetails.phraseIdentifiesMe = this.phraseIdentifiesMe
        }            

        this.userProfileDetails.knowledgeAboutCattleRaising = this.knowledgeAboutCattleRaising
        var payload = {
            profileType: this.userProfileDetails.profileType,
            profileDetails: this.userProfileDetails
        }
        axios.post('/User/SaveProfileDetails', payload )
        .then(response => {
            this.processing = false;
            if(response.data) {
                this.$bvToast.toast("Información guardada de forma exitosa", { title: 'Ok', variant: 'success', solid: true, })     
            } else {
                this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
            }
            this.$router.replace('/my-investments');
        })
        
    },
    CheckOtroOption(){
        this.motivationToInvestToString = this.motivationToInvest.toString()
        this.checkOtroWord = this.motivationToInvestToString.includes("Otro:");
        if(this.checkOtroWord){
            this.enableField = true
        } else{
            this.enableField = false
        }
    }   
    },
}
</script>


